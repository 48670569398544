.search-container {
    margin-top: 100px;
}

.table-container {
    position: relative;
}

.loading-overlay {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    background: rgba(255, 255, 255, 0.7);
    padding: 20px;
    border-radius: 8px;
    z-index: 10;
}