.location-container {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100vh;
    flex-direction: column;
    text-align: center;
}

.location-logo {
    max-width: 400px;
    height: auto;
    margin-bottom: 20px;
}

.location-form {
    margin-top: 20px;
    width: 100%;
    max-width: 400px;
}

.location-input-group {
    margin-bottom: 20px;
}

.location-label {
    display: block;
    margin-bottom: 10px;
    font-size: 18px;
    text-align: left;
}

.location-input {
    padding: 15px;
    width: 100%;
    font-size: 16px;
    border: 1px solid #ccc;
    border-radius: 4px;
}

.location-button-enabled {
    margin-top: 20px;
    padding: 15px 20px;
    width: 100%;
    font-size: 16px;
    background-color: #f57c00;
    color: #fff;
    border: none;
    border-radius: 4px;
    cursor: pointer;
    transition: background-color 0.3s ease;
}

.location-button-disabled {
    margin-top: 20px;
    padding: 15px 20px;
    width: 100%;
    font-size: 16px;
    background-color: #ccc;
    color: #fff;
    border: none;
    border-radius: 4px;
    cursor: not-allowed;
}

.location-button-enabled:hover {
    background-color: #e65100;
}

.location-input-error {
    border: 1px solid red;
}

.location-error {
    color: red;
    font-size: 12px;
    margin-top: 5px;
}