/* Stil za linkove (Merchants i Cardholders) */
.custom-link {
    color: black;
    /* Default color */
    text-decoration: none;
    /* Remove underline initially */
    transition: all 0.2s ease-in-out;
    /* Smooth transition */
}

.custom-link:hover,
.custom-link.active {
    color: blue;
    /* Blue color on hover or when active */
}

/* Stil za Logout dugme */
.custom-logout-btn {
    color: black;
    /* Default color for text */
    border-color: black;
    /* Border color */
    transition: all 0.2s ease-in-out;
    /* Smooth transition */
}

.custom-logout-btn:hover {
    background-color: black;
    /* Black background on hover */
    color: white;
    /* White text on hover */
}