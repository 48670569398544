.merchant-details {
    display: flex;
    justify-content: flex-start;
    align-items: stretch;
    padding: 20px;
    margin-top: 75px;
    margin-left: 30px;
    margin-right: 30px;
    gap: 20px;
}

.merchant-info {
    flex: 6;
    display: grid;
    grid-template-columns: 30% 70%;
    grid-gap: 10px;
    align-items: center;
    padding: 10px;

}

h2 {
    grid-column: 1 / -1;
    text-align: left;
    margin-bottom: 20px;
}

.info-item {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    margin-right: 10px;
}

.info-item label {
    font-weight: bold;
    margin-right: 10px;
}

.merchant-logo {
    flex: 1;
    display: flex;
    align-items: center;
    justify-content: flex-end;
}

.merchant-logo img {
    max-height: 200px;
    max-width: 200px;
    object-fit: contain;
    border-radius: 5px;
}

.offers-locations-tabs {
    padding-left: 50px;
    padding-right: 50px;
}

.new-offer-button {
    display: flex;
    justify-content: flex-end;
    margin: 10px;
}

.new-offer-button button {
    padding: 10px 20px;
}