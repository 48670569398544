.cardholder-details {
    display: flex;
    justify-content: space-between;
    align-items: stretch;
    padding-top: 20px;
    margin: 50px 30px;
}

.cardholder-info {
    flex: 1;
    display: grid;
    grid-template-columns: 30% 30%;
    grid-gap: 10px;
    align-items: center;
    padding: 10px;

}

h2 {
    grid-column: 1 / -1;
    /* Naslov da zauzima obe kolone */
    text-align: left;
    /* Centriraj ga levo */
    margin-bottom: 20px;
}

.info-item {
    display: flex;
    justify-content: flex-start;
    /* Labele na levoj strani */
    align-items: center;
    /* Vertikalno poravnanje */
    margin-right: 10px;
    /* Suzbijanje divova na levo */
}

.info-item label {
    font-weight: bold;
    margin-right: 10px;
}

.ag-theme-alpine {
    margin-left: 30px;
    margin-right: 30px;
}