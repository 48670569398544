.validation-modal {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.5);
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 1050;
}

.validation-modal .modal-content {
    background-color: white;
    border-radius: 20px;
    width: 90%;
    max-width: 500px;
    padding: 30px;
    text-align: center;
    box-shadow: 0 10px 30px rgba(0, 0, 0, 0.2);
    animation: fadeIn 0.3s ease-out;
}

.validation-modal.show {
    display: flex;
}

.validation-modal .modal-icon {
    font-size: 56px;
    font-weight: bold;
}

.validation-modal .validator-btn {
    width: 75%;
    background-color: #f57c00;
    border: none;
    color: white;
    font-size: 16px;
    font-weight: bold;
    border-radius: 8px;
    padding: 12px;
    transition: background-color 0.3s ease;
    margin: 0 auto;
}

.validation-modal .validator-btn:hover {
    background-color: #e65100;
}