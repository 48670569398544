.merchants-page {
    padding-top: 24px;
    display: flex;
}

.sidebar {
    display: flex;
    flex-direction: column;
    /* Rasporedi elemente vertikalno */
    position: sticky;
    top: 0;
    width: 200px;
    height: 100vh;
    background-color: #f8f9fa;
    padding: 20px;
    margin-top: 30px;
}

.sidebar h3 {
    font-size: 1.2rem;
    margin-bottom: 1rem;
}

.sidebar ul {
    list-style: none;
    padding: 0;
    flex-grow: 1;
    /* Omogućava listi da raste i zauzme prostor */
}

.sidebar li {
    padding: 10px 0;
    cursor: pointer;
    font-size: 1rem;
}

.sidebar li.active {
    font-weight: bold;
    color: #007bff;
}

.add-merchant-button {
    position: fixed;
    bottom: 20px;
    /* Od dna ekrana */
    left: 20px;
    /* Od leve strane ekrana */
    z-index: 1000;
    /* Osigurava da dugme bude iznad drugih elemenata */
}

.merchant-list {
    flex-grow: 1;
    padding: 20px;
    margin: 50px auto;
    padding: 20px;
    border: 1px solid #ccc;
    border-radius: 10px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    font-family: Arial, sans-serif;
}

.category-section {
    padding-top: 50px;
    margin-bottom: 50px;
}