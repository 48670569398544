.offer-details-container {
    display: flex;
    justify-content: space-between;
    align-items: center;
    /* Centrirano vertikalno */
    padding: 20px;
    margin: 60px 20px;
}

.offer-image {
    width: 25%;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: 20px;
    /* Dodatni razmak ako treba */
}

.offer-image img {
    max-width: 100%;
    height: auto;
    border: 1px solid #ddd;
    border-radius: 8px;
    max-height: 250px;
    max-width: 250px;
    object-fit: contain;
}

.offer-details {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    gap: 20px;
    width: 70%;
}

.detail-item {
    display: flex;
    align-items: center;
}

.detail-item label {
    font-weight: bold;
    margin-right: 10px;
}

.coupon-grid {
    width: 1100px;
    height: 400px;
    margin: 0 auto;
}

.offer-image img {
    max-height: 250px;
    max-width: 250px;
    object-fit: contain;
}

.offer-details-table {
    width: 100%;
    justify-content: center;
    margin-top: 20px;
}

.error-message {
    color: red;
    font-size: 16px;
    margin: 20px;
    text-align: center;
    display: block;
}